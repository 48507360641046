<template>
    <div class="comments rep-comments">
        <div class="comments-item">
            <div class="comments-title-row position-relative">
                <img class="comments-avatar" v-if="comment.user && comment.user !== 0 && !comment.name" :src="$helpers.isJson(comment.user.avatar) ? ($helpers.api_url() + $helpers.json_pars(comment.user.avatar).name) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                <img class="comments-avatar" v-else :src="'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                <!-- <i class="comments-title-rep-icon uil uil-repeat"></i> -->
                <div v-if="comment.user.tick_mark === 'admin'" class="is-sup is-sup-warning is-sup-m">پشتیبان</div>
                <div v-if="comment.user.tick_mark === 'analyst'"  class="is-sup is-sup-primary is-sup-m">تحلیل‌گر</div>
    
                <router-link v-if="comment.user && comment.user !== 0 && !comment.name" :to="{ name: 'ProfileOthers' , params:{ username : comment.user.username }}" class="comments-user" target="_blank">
                    <!-- {{ comment.user.firstname + ' ' + comment.user.lastname }}. -->
                    <div class="comment-reply-tag" style="margin-top: 5px;line-height: 0.7;">
                            {{ comment.user.firstname }}  {{ comment.user.lastname }} 
                            <div class="username-tag">@{{ comment.user.username }}</div>
                    </div>
                </router-link>
                <span v-else class="comments-user">
                    {{ comment.name }}
                </span>
                <div class="flex-grow-1"></div>
                <div class="comments-date">
                    <i class="uil uil-clock"></i>
                    ارسال {{ toRelative(comment.created_at) }}
                </div>
                <div class="comments-date" v-if="toRelative(comment.created_at) != toRelative(comment.modified_at)">
                    <i class="uil uil-clock"></i>
                    ویرایش {{ toRelative(comment.modified_at) }}
                </div>
    
                <div class="comments-actions" v-if="false">
                    <dropdown-menu v-model="showMenu">
                        <a href="#" class="post-btn share dropdown-toggle" @click.prevent="" v-tooltip="'مدیریت'">
                            <i class="uil uil-ellipsis-v" aria-hidden="true"></i>
                        </a>
                        <div slot="dropdown">
                            <span class="dropdown-item" @click="goReplyComment(comment.id)"><i class="uil uil-comment-lines" aria-hidden="true"></i> پاسخ</span>
                            <span v-if="user && ($helpers.userInfo().id == comment.user.id) && hiddenTimer(comment.created_at, editTimer)" class="dropdown-item" @click="goEditComment(comment.id)"><i class="uil uil-edit-alt" aria-hidden="true"></i> ویرایش</span>
                            <span v-if="user && ($helpers.userInfo().id == comment.user.id) && hiddenTimer(comment.created_at, deleteTimer)" class="dropdown-item" @click="removeComment(comment.id)"><i class="uil-trash-alt" aria-hidden="true"></i> حذف</span>
                        </div>
                    </dropdown-menu>
                </div>
            </div>
            <div class="c-reply-box">
                <div class="tgju-widget-content profile-post-content" v-html="comment.content" style="margin-top: -10px;"></div>
                <div class="post-btn-row comment-btn-row">
    
                    <div class="comments-like pointer" v-if="user">
                        <i class="uil uil-thumbs-up post-btn-icon" style="color: #db332f;" v-if="comment.liked" @click="likeComment(comment.id)"></i>
                        <i class="uil uil-thumbs-up post-btn-icon" aria-hidden="true" v-else @click="likeComment(comment.id)"></i>
                        <span class="post-btn-count">{{ comment.likes }}</span>
                    </div>
                    <v-bottom-sheet v-model="report">
                        <v-sheet class="text-center report-textarea" height="200px" >
                            <v-textarea solo name="input-7-1" v-model="reportContent" placeholder="گزارش تخلف را بنویسید...">
                            </v-textarea>
                            <div style="color: red;margin-top: -26px;margin-bottom: 5px;padding-right: 5px;" v-show="reportWarn">*متن تخلف خالی می باشد</div>
                            <v-btn color="orange" dark @click="sendReport(comment)">
                                ثبت تخلف
                            </v-btn>
                            <v-btn color="orange" dark @click="report = !report; reportContent = ''; reportWarn = false">
                                انصراف
                            </v-btn>
                        </v-sheet>
                    </v-bottom-sheet>
                    <div class="comments-like pointer" @click="report = !report">
                        <i class="uil uil-exclamation-octagon post-btn-icon"></i>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="comments-list" v-for="childComment in comment.comments" :key="childComment.id">
            <CommentItem :comments="comment.comments" :comment="childComment" :subject="subject"></CommentItem>
        </div>
    </div>
    </template>
    
    <script>
    // این کامپوننت اجزای ساب کامنت های بخش نظرات را در خود جای میدهد
    import moment from "moment-jalaali";
    import DropdownMenu from '@innologica/vue-dropdown-menu';
    
    export default {
        name: 'CommentItem',
        components: {
            DropdownMenu
        },
        props: ['comments', 'comment', 'subject'],
        data: function () {
            return {
                report: false,
                reportContent: '',
                reportWarn: false,
                user: this.$helpers.userSync(),
                showMenu: false,
                editTimer: 10,
                deleteTimer: 10,
            }
        },
        computed: {
            newPost: {
                get() {
                    return this.$parent.newPost;
                },
                set(value) {
                    this.$parent.newPost = value;
                }
            },
        },
        watch: {
            comment: {
                handler: function (newValue, oldValue) {
                    if (Object.keys(newValue).length) {
                        // تزریق یکسری اطلاعات و تنظیمات اولیه
                        this.showMenu = false;
                        this.showShareMenu = false;
                        // if(this.$helpers.isJson(this.comment.user.avatar)) {
                        // 	this.comment.user.avatar = this.$helpers.api_url() + this.$helpers.json_pars(this.comment.user.avatar).name
                        // } else {
                        // 	this.comment.user.avatar = 'https://static.tgju.org/views/default/images/tgju-user-avatar.png';
                        // }
                        // this.comment.content = this.comment.content.replaceAll(/<br><img class="post-img".*?>/gmi, '');
                        // if (this.comment.attachments.length) {
                        //     this.comment.content += `<br><img class="post-img" src="${this.$helpers.api_url() + this.comment.attachments[0].name}">`;
                        // }
                    }
                },
                immediate: true
            }
        },
        mounted() {
            this.$root.$on('LoginCallBack', (data) => {
                this.user = this.$helpers.userSync();
            });
        },
        methods: {
            hiddenTimer(created_at, timer) {
                let currentDate = moment().format();
                let minutesDiff = moment(currentDate).diff(created_at, 'minutes');
                return (minutesDiff > timer) ? false : true;
            },
            // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
            toRelative(date) {
                return moment(date).fromNow();
            },
            // این متد وظیفه درخواست لایک و آنلایک را برای یک دیدگاه انجام میدهد
            likeComment(id) {
                let index = this.$helpers.getIndex(this.comments, id);
                let comment = this.comments[index];
    
                let api_data = {
                    action: comment.liked ? 'unlike' : 'like'
                };
    
                this.$helpers.makeRequest('GET', '/tgju-post/like/' + id, api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);
    
                    if (api_response.status == 200) {
                        comment.liked = !comment.liked;
                        comment.likes = api_response.data.response.likes;
                    }
                });
            },
            // این متد وظیفه اسکرول و باز کردن بخش پاسخ به کامنت را دارد
            goReplyComment(id) {
                let index = this.$helpers.getIndex(this.comments, id);
                let comment = this.comments[index];
    
                this.newPost.parent_id = id;
                this.newPost.parent = comment;
                this.newPost.content = '@' + this.newPost.parent.user.username;
                this.newPost.type = 'tgju-comment';
    
                let parent = this.$helpers.getParent(this);
    
                parent.askParentToSetReply(this.newPost);
                // parent.askParentToScrollTo('#global-dashboard-modal[data-component="PostComments"]');
            },
            // این متد وظیفه حذف یک کامنت را دارد
            removeComment(id) {
                let index = this.$helpers.getIndex(this.comments, id);
    
                this.$helpers.confirm('هشدار حذف!', {
                    message: 'آیا از حذف این مورد اطمینان دارید؟',
                }).then((confirmed) => {
                    if (confirmed) {
                        this.$helpers.makeRequest('DELETE', '/tgju-post/delete/' + id).then(api_response => {
                            this.$helpers.api_alert(api_response);
    
                            if (api_response.status == 200) {
                                this.comments.splice(index, 1);
                            }
                        });
                    }
                });
            },
            // این متد وظیفه اسکرول و نمایش  تغییر یک کامنت را بر عهده دارد
            goEditComment(id) {
                let index = this.$helpers.getIndex(this.comments, id);
                let comment = this.comments[index];
    
                this.newPost.id = id;
                this.newPost.content = comment.content.replaceAll(/<br><img class="post-img".*?>/gmi, '');
                this.newPost.type = 'tgju-comment';
                this.newPost.attachments = comment.attachments;
    
                let parent = this.$helpers.getParent(this);
                parent.askParentToEditComment(this.newPost);
    
            },
            // گزارش تخلف
            sendReport(post) {
                if (this.reportContent.trim().length === 0) {
                    this.reportWarn = true;
                    return;
                }
                this.$helpers.makeRequest('POST', '/tgju-post/report' , { 
                    content : this.reportContent,
                    post_id : post.id,
                }).then(api_response => {
                    this.$helpers.api_alert(api_response);
                    if (api_response.status == 200) {
                        this.$swal.fire({
                                icon: api_response.data.response.status,
                                title: api_response.data.response.message,
                                showConfirmButton: !1,
                                timer: 2000
                            });
                    } else if (api_response.status == 404) {
                            this.$swal.fire({
                                icon: "error",
                                title: 'مشکلی در ارتباط با سرور رخ داده است.',
                                showConfirmButton: !1,
                                timer: 2000
                            });
                    }
                });
                this.report = false;
                this.reportContent = '';
                this.reportWarn = false;
            }
        },
    }
    </script>
    